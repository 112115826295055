<template>
<div>
  <b-container fluid>
    <b-row class="mb-2">
      <b-col class="text-center">
        <b-card>
          <b-nav pills>
          <b-nav-item to="/impact/policies/download/choose-data" exact exact-active-class="active">Step 1: choose data</b-nav-item>
          <b-nav-item to="/impact/policies/download/choose-template/basic" exact exact-active-class="active" :active="activeTemplate">Step 2: choose template</b-nav-item>
        </b-nav>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
  <router-view></router-view>
</div>
</template>

<script>

export default {
  name: 'PoliciesDownloadLayout',
  components: {
  },
  computed: {
    activeTemplate () {
      return this.$route.path.includes('choose-template')
    }
  },
  data () {
    return {
    }
  },
  created: async function () {
    document.title = "Climate Policy and Regulatory Dashboard"
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style>
</style>
